<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from "js-cookie";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
    };
  },
  created() {

    // Shut the fuck up
    const log = console.log;
    console.log = () => { };
    const warn = console.warn;
    console.warn = () => { };
    const error = console.error;
    console.error = () => { };

    let adat = { qr: 0, lang: 0, ll: 0, udw: 0 };
    let last = { zh: 1, en: 2, fr: 3 };

    if (this.$route.query.qr) {
      adat.qr = this.$route.query.qr;
      history.replaceState(null, "", '/');
    }

    // 语言设定
    let lastlang = localStorage.getItem("lang");
    if (lastlang == 'en' || lastlang == 'zh' || lastlang == 'fr') {
      this.$i18n.locale = lastlang;
      adat.ll = last[lastlang];
    }
    else {
      // 获取浏览器的语言
      var navilang = navigator.language;

      if (navilang.startsWith('zh')) {
        this.$i18n.locale = 'zh';
      } else if (navilang.startsWith('fr')) {
        this.$i18n.locale = 'fr';
      } else {
        this.$i18n.locale = 'en';
      }
      window.localStorage.setItem("lang", this.$i18n.locale);
    }

    // if (!Cookies.get('uid')) {
    adat.lang = last[this.$i18n.locale];
    adat.udw = screen.width;
    let rt = { tat: btoa(JSON.stringify(adat)) }
    axios.post('/api/check', rt)
      .then(function (response) {
        console.log(response);
        if (response.status == 200 && response.data.code == 0) {
          Cookies.set("uid", response.data?.data, { expires: 1 });
        }
      })
      .catch(function (er) {
        console.log(er);
      })
    // }
  },
  mounted() {
    this.isWechat() && this.getShareInfo() 
  },
  methods: {
    isWechat() {
      let ua = navigator.userAgent.toLowerCase()
      let isWXWork = ua.match(/wxwork/i) == 'wxwork'
      let isWeixin = !isWXWork && ua.match(/MicroMessenger/i) == 'micromessenger'
      return isWeixin
    },
    getShareInfo() {
      //获取url链接（如果有#需要这么获取）
      // var url = encodeURIComponent(window.location.href.split("#")[0]);
      // 获取url链接（如果没有#需要这么获取）
      // var url = encodeURIComponent(window.location.href);
      let url = "https://www.gomansguitar.com/api/jsign";

      axios.post(
        url,
        { url: window.location.href }
      ).then(res => {
        console.log(res.data?.message);
        let wxcfg = JSON.parse(res.data?.message)
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: wxcfg.appId, // 必填，公众号的唯一标识
          timestamp: parseInt(wxcfg.timestamp), // 必填，生成签名的时间戳
          nonceStr: wxcfg.nonceStr, // 必填，生成签名的随机串
          signature: wxcfg.signature, // 必填，签名
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData"
          ] // 必填，需要使用的 JS 接口列表
        });
        wx.ready(() => {
          var shareData = {
            title: "Gomans吉他",
            desc: "Gomans吉他线上三维定制",
            link: window.location.href,
            imgUrl: "https://www.gomansguitar.com/home/02.jpg",
            success: function () {
              console.log("分享成功");
            }
          };
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          wx.updateAppMessageShareData(shareData);
          //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(shareData);
        });
        //错误了会走 这里
        wx.error(function (res) {
          console.log("微信分享错误信息： ", res);
        });
      });
    },

  }
}
</script>

<style scoped>
#app {
  /* Avenir, Helvetica, Arial, sans-serif,  */
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #000;
  height: 100%;
  width: 100%;
}
</style>